import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106')
];

export const server_loads = [0,3,5,6,8,10,11,12,15,16,18,19,23,27,28];

export const dictionary = {
		"/": [~30],
		"/agenda": [~31,[2]],
		"/calendar": [~32,[3]],
		"/calendar/weekly": [~33,[3]],
		"/changelog": [~34,[4]],
		"/dashboard": [~35,[5]],
		"/delete-me": [36],
		"/invitation/[slug]": [~37,[6]],
		"/invitation/[slug]/login": [~38,[6]],
		"/login": [~39],
		"/logout": [~40],
		"/password-reset/[slug]": [~41],
		"/patients": [~42,[7]],
		"/patients/[id]": [~43,[7,8]],
		"/patients/[id]/actions": [~44,[7,8,9]],
		"/patients/[id]/billing": [45,[7,8,10]],
		"/patients/[id]/billing/[iid]": [~46,[7,8,10]],
		"/patients/[id]/chart": [~47,[7,8,11]],
		"/patients/[id]/chart/dentition": [~64,[7,8,11]],
		"/patients/[id]/chart/endodontic": [65,[7,8,11]],
		"/patients/[id]/chart/periodontal": [66,[7,8,11]],
		"/patients/[id]/chart/[tid]": [48,[7,8,11,12]],
		"/patients/[id]/chart/[tid]/caries": [49,[7,8,11,12]],
		"/patients/[id]/chart/[tid]/conditions": [50,[7,8,11,12]],
		"/patients/[id]/chart/[tid]/conditions/[cid]": [51,[7,8,11,12]],
		"/patients/[id]/chart/[tid]/diastema": [52,[7,8,11,12]],
		"/patients/[id]/chart/[tid]/extraction": [53,[7,8,11,12]],
		"/patients/[id]/chart/[tid]/extraction/[eid]": [54,[7,8,11,12]],
		"/patients/[id]/chart/[tid]/impacted": [55,[7,8,11,12]],
		"/patients/[id]/chart/[tid]/missing": [56,[7,8,11,12]],
		"/patients/[id]/chart/[tid]/movements": [57,[7,8,11,12]],
		"/patients/[id]/chart/[tid]/permanent": [~58,[7,8,11,12]],
		"/patients/[id]/chart/[tid]/primary": [59,[7,8,11,12]],
		"/patients/[id]/chart/[tid]/procedures": [60,[7,8,11,12]],
		"/patients/[id]/chart/[tid]/procedures/[pid]": [61,[7,8,11,12]],
		"/patients/[id]/chart/[tid]/restorations": [62,[7,8,11,12]],
		"/patients/[id]/chart/[tid]/restorations/[rid]": [63,[7,8,11,12]],
		"/patients/[id]/general": [~67,[7,8,13]],
		"/patients/[id]/general/appointments": [~68,[7,8,13,14]],
		"/patients/[id]/general/consent2": [69,[7,8,13,15]],
		"/patients/[id]/general/consent2/[cid]": [~70,[7,8,13,15]],
		"/patients/[id]/general/medical-history": [~71,[7,8,13]],
		"/patients/[id]/general/messages": [72,[7,8,13]],
		"/patients/[id]/general/prescription": [~73,[7,8,13,16]],
		"/patients/[id]/general/prescription/new": [~75,[7,8,13,16,17]],
		"/patients/[id]/general/prescription/[pid]": [~74,[7,8,13,16]],
		"/patients/[id]/general/profile": [~76,[7,8,13]],
		"/patients/[id]/timeline": [~77,[7,8]],
		"/patients/[id]/whiteboard/[wid]": [~78,[7,8]],
		"/register": [~79],
		"/sales": [~80,[18]],
		"/sales/by-doctor": [81,[18,19]],
		"/sales/by-doctor/[id]": [~82,[18,19]],
		"/sales/by-patient": [~83,[18]],
		"/settings": [~84,[20]],
		"/settings/account": [~85,[20]],
		"/settings/aftercare": [~86,[20,21]],
		"/settings/aftercare/new": [~88,[20,21,22]],
		"/settings/aftercare/[id]": [~87,[20,21]],
		"/settings/branches": [~89,[20]],
		"/settings/branches/[id]": [~90,[20]],
		"/settings/consent2": [~91,[20,23]],
		"/settings/consent2/new": [~93,[20,23]],
		"/settings/consent2/[id]": [~92,[20,23]],
		"/settings/payouts": [~94,[20,24]],
		"/settings/preferences": [95,[20,25]],
		"/settings/prescription": [~96,[20,26]],
		"/settings/prescription/new": [~98,[20,26]],
		"/settings/prescription/[id]": [~97,[20,26]],
		"/settings/price-list": [99,[20,27]],
		"/settings/price-list/[id]": [~100,[20,27]],
		"/settings/profile": [~101,[20]],
		"/settings/subscription": [~102,[20,28]],
		"/settings/subscription/change": [~103,[20,28]],
		"/settings/subscription/purchases": [~104,[20,28]],
		"/settings/subscription/sms": [~105,[20,28]],
		"/settings/users": [~106,[20,29]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';